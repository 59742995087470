import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/Components/integration/portfolio/PortfolioTable.module.css";
import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { formatAmount } from "../../../utility/helper";
import { getRelativeTime } from "../../../utility/formatter-helper";
import { Button, message, Table } from "antd";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { updateExchangeHistory } from "../../../redux/exchange/actions";
import ComprehensiveHistoryTableExpandedRow from "./ComprehensiveHistoryTableExpandedRow";

function ComprehensiveHistoryTable({ type, loading, historyData, exchange }) {
  const {
    main: { userData },
    exchangeState: {
      // exchangeComprehensiveHistoryLoading,
      // exchangeComprehensiveHistory,
      // selectedPortfolioExchange,
      isMaximumHistory,
    },
    tradingAssistant: { getTradeHistoryLoading, comprehensiveHistory },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const data = type === "exchange" ? historyData : comprehensiveHistory;

  const sortedHistory = [...data]?.sort((a, b) => {
    return moment(b["Trade Date"], "DD MMM YYYY, HH:mm:ss").diff(
      moment(a["Trade Date"], "DD MMM YYYY, HH:mm:ss"),
    );
  });

  const onRowClick = (record) => {
    if (expandedRowKeys.includes(record.key)) {
      setExpandedRowKeys([]);
      return;
    }
    setExpandedRowKeys([record.key]);
  };

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    if (loading) return;
    if (isMaximumHistory)
      return message.info(
        "You have reached the end of the history. All available data has been displayed.",
      );

    const latestItemDate =
      sortedHistory?.[sortedHistory?.length - 1]?.["Trade Date"];

    // Dispatch action to get more data based on the latest item's date
    if (userData?.email && exchange) {
      dispatch(
        updateExchangeHistory(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          exchange,
          moment(latestItemDate, "DD MMM YYYY, HH:mm:ss").format(
            "YYYY-MM-DD HH:mm:ss.SSSSSS",
          ), // Send the date of the latest item for pagination
        ),
      );
    }
  };

  const columns = [
    {
      title: <span className={styles.title}>Position</span>,
      dataIndex: "position",
      key: "position",
      align: "center",
      responsive: ["lg"],
      width: 50,
      render: (text) => <span className={styles.positionBtn}>Long</span>,
    },

    {
      title: <span className={styles.title}>Date</span>,
      dataIndex: "date",
      key: "date",
      width: 80,
      align: "center",
      render: (text) => (
        <span
          // onClick={() => clickHandler(text)}
          className={`${styles.tableContent}`}
        >
          {getRelativeTime(text)}
        </span>
      ),
    },
    {
      title: <span className={styles.title}>Asset</span>,
      dataIndex: "asset",
      key: "asset",
      align: "center",
      width: 50,
      render: (text) => (
        <span className={styles.tableContent}>
          <img
            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${text}.png`}
            alt={text}
            className={styles.coinIcon}
          />
          {text}
        </span>
      ),
    },
    {
      title: <span className={styles.title}>Amount</span>,
      dataIndex: "amount",
      key: "amount",
      width: 60,
      responsive: ["md"],
      align: "center",
      render: (text) => (
        <span
          // onClick={() => clickHandler(text)}
          className={`${styles.tableContent}`}
        >
          {formatAmount(text)}
        </span>
      ),
    },
    {
      title: <span className={styles.title}>Entry Price</span>,
      dataIndex: "entryPrice",
      key: "entryPrice",
      width: 60,
      responsive: ["xl"],
      align: "center",
      render: (text) => (
        <span
          // onClick={() => clickHandler(text)}
          className={`${styles.tableContent}`}
        >
          {text}
        </span>
      ),
    },
    {
      title: <span className={styles.title}>Exit Price</span>,
      dataIndex: "exitPrice",
      key: "exitPrice",
      width: 60,
      responsive: ["xl"],
      align: "center",
      render: (text) => (
        <span
          // onClick={() => clickHandler(text)}
          className={`${styles.tableContent}`}
        >
          {text}
        </span>
      ),
    },
    {
      title: <span className={styles.title}>Profit/Loss</span>,
      dataIndex: "profitLoss",
      key: "profitLoss",
      width: 80,
      responsive: ["xs", "sm"],
      align: "center",
      render: (text) => (
        <span
          // onClick={() => clickHandler(text)}
          className={`${styles.tableContent}`}
          style={{ color: text?.includes("-") ? "red" : "green" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: <span className={styles.title}>Trader</span>,
      dataIndex: "trader",
      key: "trader",
      width: 85,
      align: "center",
      responsive: ["sm"],
      render: (text) => (
        <span
          // onClick={() => clickHandler(text)}
          className={`${styles.tableContent}`}
        >
          {text.replace("_", " ")}
        </span>
      ),
    },
    {
      title: (
        <span className={styles.title}>
          {window.innerWidth > 768 ? "Trade Status" : "Status"}
        </span>
      ),
      dataIndex: "status",
      key: "status",
      width: 65,
      responsive: ["md"],
      align: "center",
      render: (text, record) => (
        <span className={`${styles.tableContent} ${styles.status}`}>
          {text}
        </span>
      ),
    },
  ];

  useEffect(() => {
    setTableData(
      sortedHistory?.map((item, index) => {
        return {
          key: index,
          position: item?.Position,
          asset: item?.["Asset"],
          currency: item?.currency,
          amount: item?.Amount,
          action: item?.trade_type,
          price: item?.trigger_price,
          date: item?.["Trade Date"],
          orderMode: item?.order_mode,
          trader: item?.Trader,
          status: item?.Position,
          info: item,
          entryPrice: item?.["Entry Price"],
          exitPrice: item?.["Exit Price"],
          profitLoss: item?.PnL,
        };
      }),
    );
  }, [historyData, comprehensiveHistory]);

  return (
    <div>
      <Table
        loading={type === "exchange" ? loading : getTradeHistoryLoading}
        columns={columns}
        dataSource={tableData}
        height={500}
        scroll={{ y: 500 }}
        className={`${styles.portfolioTable} expanded-table`}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <ComprehensiveHistoryTableExpandedRow data={record} />
          ),
          expandedRowKeys,
          expandIcon: ({ expanded, onExpand, record }) => {
            if (expanded) {
              return (
                <IoChevronUp
                  className={styles.expandIcon}
                  onClick={(e) => {
                    onRowClick(record);
                  }}
                />
              );
            } else {
              return (
                <IoChevronDown
                  className={styles.expandIcon}
                  onClick={(e) => {
                    onRowClick(record);
                  }}
                />
              );
            }
          },
        }}
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })}
      />

      {type === "exchange" &&
        historyData?.length > 0 &&
        historyData?.length > 10 && (
          <div className={styles.showMoreBtn}>
            <Button
              onClick={handlePageChange}
              loading={loading}
              style={{ opacity: `${isMaximumHistory ? 0.7 : 1}` }}
            >
              Show more
            </Button>
          </div>
        )}
    </div>
  );
}

export default ComprehensiveHistoryTable;
