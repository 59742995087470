import { Button, Checkbox, message, Modal, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styles from "../../styles/shared/live-suggestion/LiveSuggestionButton.module.css";
import { IoMdArrowDropright } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssetList,
  getIntegratedExchanges,
} from "../../redux/exchange/actions";
import { get200Coins } from "../../redux/trading-assistant/actions";
import { getLiveSuggestion } from "../../redux/live-suggestion/liveSuggestionSlice";
import SelectExchange from "../SelectExchange";
import { Link } from "react-router-dom";

// const defaultExchange = "swyftx";
const defaultExchange = "kucoin";

const LiveSuggestionModal = ({ show, setShow, coins }) => {
  const {
    main: { userData },
    exchangeState: {
      exchangesInfo,
      exchangesLoading,
      integratedData,
      integratedDataLoading,
      getIntegratedList,
      assetsLoading,
      assetsList,
    },
    tradingAssistant: {
      _200Coins: { top_200_coin: topCoins = [] },
      get200CoinsLoading,
      get200CoinsError,
    },
    liveSuggestion: { suggestions, status, errorMessage },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [selectedExchange, setSelectedExchange] = useState(null);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [doBacktest, setDoBacktest] = useState(false); // State for checkbox

  const symbolsInputRef = useRef(null);

  const symbols = !selectedExchange
    ? []
    : selectedExchange === "cryptocrispy"
      ? topCoins
      : assetsList;

  const symbolOptions =
    get200CoinsLoading || assetsLoading
      ? [{ label: "Loading...", value: "", disabled: true }]
      : symbols.map((item) => {
          return {
            value: item,
            label: (
              <p className={styles.symbolOption}>
                <img
                  className={styles.symbolIcon}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${
                    item.includes("/") ? item.split("/")[0] : item
                  }.png`}
                  alt={item.includes("/") ? item.split("/")[0] : item}
                />
                {item}
              </p>
            ),
          };
        });

  const handleCloseModal = () => {
    setSelectedExchange(null);
    setSelectedSymbol(null);
    setShow(false);
  };

  const handleExchangeChange = (value) => {
    setSelectedExchange(value);
    setSelectedSymbol(null); // Reset selected coin when exchange changes
  };

  const handleSymbolChange = (value) => {
    symbolsInputRef.current.blur();
    setSelectedSymbol(value);
  };

  const handleSubmit = async () => {
    if (!selectedSymbol || !selectedExchange) {
      message.error("Please select both an exchange and a coin.");
      return;
    }

    const exchange =
      selectedExchange === "cryptocrispy" || selectedExchange === "swyftx"
        ? "binance"
        : selectedExchange;
    await dispatch(
      getLiveSuggestion(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        exchange,
        selectedSymbol,
        doBacktest,
      ),
    ).then(() => setShow(false));
  };

  useEffect(() => {
    if (userData?.email && !integratedDataLoading && !getIntegratedList) {
      dispatch(
        getIntegratedExchanges(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [dispatch, getIntegratedList, integratedDataLoading, userData]);

  // Automatically set default exchange if no integrations are available
  useEffect(() => {
    if (integratedData.length === 0 && !integratedDataLoading) {
      setSelectedExchange(defaultExchange);
    }

    if (integratedDataLoading) {
      setSelectedExchange(null);
    }
  }, [integratedData?.length, integratedDataLoading]);

  // FETCH COINS LIST
  useEffect(() => {
    if (!topCoins?.length && !get200CoinsLoading && !get200CoinsError) {
      dispatch(
        get200Coins(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [dispatch, get200CoinsError, get200CoinsLoading, topCoins?.length]);

  useEffect(() => {
    if (selectedExchange && selectedExchange !== "cryptocrispy") {
      dispatch(
        getAssetList(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedExchange,
        ),
      );
    }
  }, [selectedExchange]);

  return (
    <Modal open={show} onCancel={handleCloseModal} centered footer={null}>
      <div className={styles.box}>
        <h4>
          <IoMdArrowDropright />
          Select an Exchange
        </h4>
        <SelectExchange
          value={selectedExchange}
          onChange={handleExchangeChange}
          style={{ width: "100%" }}
        />
      </div>

      <div className={styles.box}>
        <h4>
          <IoMdArrowDropright />
          Select a Coin
        </h4>
        <Select
          style={{ width: "100%" }}
          ref={symbolsInputRef}
          placeholder="Select a coin"
          value={selectedSymbol}
          onChange={handleSymbolChange}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={symbolOptions}
          disabled={!selectedExchange}
        />

        {!assetsList.length && !assetsLoading && selectedExchange && (
          <p className={styles.errorMessage}>
            Please <Link to="/integration">integrate</Link> first!
          </p>
        )}
      </div>

      <div className={styles.box}>
        <Checkbox
          checked={doBacktest}
          onChange={(e) => setDoBacktest(e.target.checked)}
        >
          Do Backtest
        </Checkbox>
      </div>

      <Button
        onClick={handleSubmit}
        loading={status === "loading"}
        className={styles.submitButton}
        disabled={!selectedSymbol || !selectedSymbol}
      >
        Confirm
      </Button>
    </Modal>
  );
};

function LiveSuggestionButton({
  title = "",
  btnClassName = "",
  btnStyle = {},
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <span
        className={`${styles.mainBtn} ${btnClassName}`}
        style={btnStyle}
        onClick={() => {
          setShowModal(true);
        }}
      >
        {title || "Ask for live suggestion"}
      </span>

      {showModal && (
        <LiveSuggestionModal show={showModal} setShow={setShowModal} />
      )}
    </>
  );
}

export default LiveSuggestionButton;
