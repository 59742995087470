import axios from "axios";
import { Modal } from "antd";
import { errorHandler, expiredTokenHandler } from "./helper";

export const maintenanceErrorCodes = [502, 410, 413];

// This function should be used for the backend error with these status code: 502, 410, 413 and 504 (504 even if after two times it still exist)
export const logErrorToTelegram =
  (user, email, error_code, request, response, route, showError = true) =>
  async (dispatch) => {
    if (showError && request !== "inference") {
      showMaintenanceError();
    }

    await axios
      .post(
        "https://nuih8j65u3.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          description: "test",
          user,
          email,
          error_code,
          request,
          response,
          route,
        },
      )
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 450 && showError) {
          expiredTokenHandler("");
        }
      });
  };

export const showMaintenanceError = () => {
  return Modal.info({
    content: (
      <p style={{ marginBottom: 0, fontSize: "16px" }}>
        Dear user, we apologize for the inconvenience. <br />
        Our system is currently undergoing maintenance to provide you with a
        better service. Please check back later.
        <br />
        We appreciate your patience and understanding. Thank you!
      </p>
    ),
    centered: true,
  });
};

export const axiosErrorHandler = (
  error,
  retryCount,
  dispatch,
  callbackFunc,
  actionType,
  user,
  email,
  requestType,
  gap = 0,
) => {
  // console.log(
  //   error,
  //   retryCount,
  //   dispatch,
  //   callbackFunc,
  //   actionType,
  //   user,
  //   email,
  //   requestType,
  // );
  console.log("error:", error, "retryCount:", retryCount);

  if (error?.response?.status === 504) {
    console.log("504 error");
    if (retryCount < 2) {
      console.log("retry to fetch");
      if (gap && retryCount === 0) {
        setTimeout(() => {
          dispatch(callbackFunc());
        }, gap * 1000);
      } else {
        dispatch(callbackFunc());
      }
    } else {
      dispatch({
        type: actionType,
        payload: error?.response?.data?.message,
      });
      dispatch(
        logErrorToTelegram(
          user,
          email,
          error?.response?.status,
          requestType,
          error?.response?.data,
          window?.location?.href,
        ),
      );
    }
  } else if (maintenanceErrorCodes.includes(error?.response?.status)) {
    dispatch({
      type: actionType,
      payload: error?.response?.data?.message || error?.response?.data?.MESSAGE,
    });
    dispatch(
      logErrorToTelegram(
        user,
        email,
        error?.response?.status,
        requestType,
        error?.response?.data,
        window?.location?.href,
      ),
    );
  } else {
    dispatch({ type: actionType, payload: error });
    const { STATUS_CODE, MESSAGE } = error?.response.data;
    errorHandler(STATUS_CODE, MESSAGE || error?.response?.data?.message);
  }
};
