import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { Spin, Table, Tooltip } from "antd";
import styles from "../../styles/Components/tables/portfolioTable.module.sass";
import { useDispatch, useSelector } from "react-redux";
import SignalStrengthUi from "../trading-assistant/SignalStrengthUi";
import { FiRefreshCcw } from "react-icons/fi";
import { setBasicTradeInfo, setMode, setStep } from "../../redux/trade/actions";
import TradeModal from "../../shared/trade-modal/TradeModal";
import {
  customSort,
  errorHandler,
  formatPrice,
  refreshLimit,
} from "../../utility/helper";
import ColumnTitle from "./trade-assistant-table/ColumnTitle";
import ExpandedRowTradeAssistantTable from "../trading-assistant/assistant/ExpandedRowTradeAssistantTable";
import { MdOutlineUpdate } from "react-icons/md";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import refreshIcon from "../../asset/Icons/notification(1).gif";
import AIAssistantServiceActivationModal from "../trading-assistant/AIAssistantServiceActivationModal";
import HideContentIcon from "../mini-app/shared/HideContentIcon";
import AiAssistantTableActionButtons from "../trading-assistant/assistant/AiAssistantTableActionButtons";
import AssetDisplay from "../../ui/AssetDisplay";
import OtherCoins from "../../shared/other-coins-signal/OtherCoins";
import {
  FaArrowCircleDown,
  FaArrowCircleUp,
  FaBrain,
  FaPauseCircle,
} from "react-icons/fa";
import LiveSuggestionModal from "../../shared/live-suggestion/LiveSuggestionModal";
import { getRelativeTime } from "../../utility/formatter-helper";
import { getLiveSuggestion } from "../../redux/live-suggestion/liveSuggestionSlice";
import { RiExchangeFundsLine } from "react-icons/ri";

const TradeAssistantTable = ({ data }) => {
  const [showTradeModal, setShowTradeModal] = useState(false);
  const [priceRefreshTime, setPriceRefreshTime] = useState(60);
  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);

  const refreshInterval = useRef(null);
  const countdownInterval = useRef(null);

  const {
    tradingAssistant: {
      getTradeAssistantLoading,
      tradeAssistant,
      aiMarketType,
      allTradeSuggestionData,
    },
    main: { userData },
    profile: { profileData },
    liveSuggestion: { suggestions, status },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { minutes_passed, minutes_remaining } = tradeAssistant;

  const [tableData, setTableData] = useState([]);
  // create state to handle counter for update trade suggestion
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [passedMinutes, setPassedMinutes] = useState(0);

  // STATE TO HANDLE SHOWING LIVE AI SUGGESTION'S MODAL
  const [showLiveSuggestionModal, setShowLiveSuggestionModal] = useState(false);
  const [liveSuggestionTarget, setLiveSuggestionTarget] = useState(null);

  const stopLossCFC = tradeAssistant?.coefficients?.stop_loss;
  const takeProfitCFC = tradeAssistant?.coefficients?.take_profit;

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const isServiceActive = profileData?.myais_active === "True";

  const liveSuggestionDate = getRelativeTime(
    suggestions?.[0]?.signal_timestamp,
  );
  // handle refresh live suggestions
  const lastProcessedTimestamp = useRef(null);

  const onRowClick = (record) => {
    if (expandedRowKeys.includes(record.key)) {
      return setExpandedRowKeys([]);
    }
    setExpandedRowKeys([record.key]);
  };

  const openPaymentModal = (e) => {
    if (!isServiceActive) {
      e.stopPropagation();
      setShowServiceActivationModal(true);
    }
  };

  const setTradeHandler = (item, e) => {
    e.stopPropagation();

    if (!isServiceActive) {
      setShowServiceActivationModal(true);
      return;
    }

    if (aiMarketType !== "crypto") return;

    const {
      MARKET_TYPE: market,
      SYMBOL_NAME: asset,
      STOP_LOSS_PERCENT: stopLossPercent,
      TAKE_PROFIT_PERCENT: takeProfitPercent,
      SUGGESTED_AMOUNT_PERCENTAGE: amountPercent,
      TRADE_TYPE: tradeType,
    } = item;

    dispatch(
      setBasicTradeInfo({
        market,
        asset,
        tradeType,
        stopLossPercent: stopLossPercent * stopLossCFC,
        takeProfitPercent: takeProfitPercent * takeProfitCFC,
        amountPercent: amountPercent * 100,
      }),
    );
    dispatch(setStep("1-d"));
    dispatch(setMode("ai-suggestion"));
    setShowTradeModal(true);
  };

  const handleUpdatePrice = async () => {
    clearInterval(refreshInterval.current);
    clearInterval(countdownInterval.current);
    setPriceRefreshTime(0);

    tableData.map((item) => symbols.push(item?.coin?.SYMBOL_NAME));
    await axios
      .post(
        "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          platform_mode: "trade_assistant",
          user: userData?.["custom:custom_username"],
          email: userData?.["cognito:username"],
          request_type: "get_price_bulk",
          market_type: "crypto",
          symbol_names: symbols,
        },
      )
      .then((res) => {
        const priceData = res?.data?.PRICE_LIST;
        const priceList = [];
        priceData.map((item) => priceList.push(item?.PRICE));

        // UPDATE PRICES
        setTableData((prevData) => {
          return prevData.map((item, i) => {
            const {
              buyNow: { TRADE_TYPE: tradeType },
            } = item;

            const priceColor =
              item?.currentPrice < priceList[i] ? "#70AF85" : "#EF4B4B";

            return {
              ...item,
              currentPrice: priceList[i],
              stopLoss: refreshLimit(
                item.stopLossPercent,
                priceList[i],
                tradeType,
                "stopLoss",
              ),
              takeProfit: refreshLimit(
                item.takeProfitPercent,
                priceList[i],
                tradeType,
                "takeProfit",
              ),
              priceColor,
              // stopLoss: refreshLimit(item.stopLossPercent, priceList[i]),
              // takeProfit: refreshLimit(item.takeProfitPercent, priceList[i]),
            };
          });
        });
        setPriceRefreshTime(60);
      })
      .catch((err) => {
        const { STATUS_CODE, MESSAGE } = err?.response.data;
        if (MESSAGE) {
          errorHandler(STATUS_CODE, MESSAGE);
        }
        console.log(err);
      });
  };

  // States to handle showing percent or usd of column
  const [isPercent, setIsPercent] = useState(true);
  const symbols = tableData?.map((item) => item?.coin?.SYMBOL_NAME);

  const columns = useMemo(() => {
    return [
      {
        title: (
          <Tooltip title="Cryptocurrency symbol" placement={"top"}>
            <span className={styles.headerTitle}>Asset</span>
          </Tooltip>
        ),
        dataIndex: "coin",
        key: "coin",
        width: 150,
        align: "center",
        responsive: ["xs", "sm"],
        render: (item) => {
          return item?.SYMBOL_NAME ? (
            <AssetDisplay asset={item?.SYMBOL_NAME} />
          ) : (
            <OtherCoins disabledCoins={symbols} />
          );
        },
      },
      {
        title: (
          <Tooltip title="Suggested trading action" placement={"top"}>
            <span className={styles.headerTitle}>1H Signal</span>
          </Tooltip>
        ),
        dataIndex: "type",
        key: "type",
        width: 110,
        align: "center",
        responsive: ["xs", "sm"], // show only on small devices
        render: (text) =>
          text?.TRADE_TYPE ? (
            <>
              <span
                className={`${
                  text?.TRADE_TYPE === "Buy"
                    ? styles.buy
                    : text?.TRADE_TYPE === "Sell"
                      ? styles.sell
                      : styles.hold
                } ${!isServiceActive ? styles.hideContent : ""}`}
                style={{ cursor: "pointer" }}
                onClick={(e) => setTradeHandler(text, e)}
              >
                {!isServiceActive ? (
                  <HideContentIcon />
                ) : text?.TRADE_TYPE === "Sell" ? (
                  "Sell/Short"
                ) : text.TRADE_TYPE === "Buy" ? (
                  "Buy/Long"
                ) : (
                  text.TRADE_TYPE
                )}
              </span>
              <span
                style={{
                  fontSize: "12px",
                  marginTop: ".5rem",
                  display: "inline-block",
                }}
              >
                {60 - remainingMinutes === 60
                  ? "1 hour ago"
                  : `${60 - remainingMinutes} minutes ago`}
              </span>
            </>
          ) : (
            ""
          ),
      },
      {
        title: (
          <span
            className={styles.headerTitle}
            // title={
            //   "Confidence level in the accuracy of the provided trading recommendation"
            // }
          >
            15M Signal
            {liveSuggestionDate === "15 minutes ago" && (
              <Spin size="small" style={{ marginLeft: ".5rem" }} />
            )}
          </span>
        ),
        dataIndex: "liveSuggestion",
        key: "liveSuggestion",
        width: 140,
        align: "center",
        responsive: ["xs", "sm"], // hide on small and medium devices
        render: (item) => {
          if (!item) return "";

          const symbolName = item?.SYMBOL_NAME?.includes("/USDT")
            ? item?.SYMBOL_NAME
            : `${item?.SYMBOL_NAME}/USDT`;

          const liveData = suggestions?.find((s) => s?.symbol === symbolName);
          const predictionIcon =
            liveData?.prediction === "Sell" ? (
              <FaArrowCircleDown size={18} color="#EF4B4B" />
            ) : liveData?.prediction === "Buy" ? (
              <FaArrowCircleUp size={18} color="#70AF85" />
            ) : (
              <FaPauseCircle size={18} color="#82A0D8" />
            );

          const date = liveData
            ? getRelativeTime(liveData?.signal_timestamp)
            : "";

          return (
            <div className={`${styles.tableContent} ${styles.liveSuggestion}`}>
              {status === "loading" && !liveData ? (
                <Spin size="small" />
              ) : !liveData && status === "error" ? (
                <RiExchangeFundsLine color="red" />
              ) : !liveData ? (
                <span>-</span>
              ) : (
                <>
                  <span className={styles.predictionIcon}>
                    {predictionIcon}
                  </span>
                  <span>{liveData?.prediction}</span>
                  <span>
                    Confidence:{" "}
                    <span
                      style={{
                        color: `${liveData?.confidence >= 0.5 ? "inherit" : "grey"}`,
                      }}
                    >
                      {(liveData?.confidence * 100).toFixed(2)}%
                    </span>
                  </span>
                  <span>{date}</span>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: ".2rem",
                      cursor: "pointer",
                      color: "#0C4CFC",
                      flexWrap: "wrap",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowLiveSuggestionModal(true);
                      setLiveSuggestionTarget(symbolName);
                    }}
                  >
                    <FaBrain className={styles.liveBtn} color="#0C4CFC" />
                    <span>Run AI Now</span>
                  </div>
                </>
              )}
            </div>
          );
        },
      },
      {
        title: (
          <Tooltip
            title={"Analytical category guiding the trading decision"}
            placement={"top"}
          >
            <span className={styles.headerTitle}>Category</span>
          </Tooltip>
        ),
        dataIndex: "category",
        key: "category",
        align: "center",
        width: 120,
        responsive: ["lg"], // hide on small and medium devices
        render: (text) =>
          text ? (
            <span className={styles.tableContent}>
              {text
                ? text === "On-Chain"
                  ? text
                  : text.replace(/-/g, " ")
                : "No Category"}
            </span>
          ) : (
            ""
          ),
      },
      {
        title: (
          <Tooltip
            title={"Real-time value of the cryptocurrency in the market"}
            placement={"top"}
          >
            <span className={styles.headerTitle}>
              Current Price
              {!priceRefreshTime > 0 ? (
                <Spin size="small" style={{ marginLeft: ".2rem" }} />
              ) : (
                !getTradeAssistantLoading &&
                tableData &&
                tableData.length > 0 &&
                aiMarketType === "crypto" && (
                  <FiRefreshCcw
                    style={{ cursor: "pointer", marginLeft: ".2rem" }}
                    size={14}
                    onClick={handleUpdatePrice}
                  />
                )
              )}
            </span>
          </Tooltip>
        ),
        dataIndex: "currentPrice",
        key: "currentPrice",
        width: 110,
        align: "center",
        responsive: ["sm"], // hide on small and medium devices

        render: (text, record) => {
          return text ? (
            <span
              className={styles.tableContent}
              style={record?.priceColor ? { color: record?.priceColor } : {}}
            >
              ${formatPrice(text, 3)}
            </span>
          ) : (
            ""
          );
        },
      },
      {
        title: (
          <ColumnTitle
            title={
              <Tooltip
                title={"Protection level to minimize potential losses"}
                placement={"top"}
              >
                <span className={styles.headerTitle}>Stop Loss</span>
              </Tooltip>
            }
            mode={isPercent}
            toggleMode={setIsPercent}
          />
        ),
        dataIndex: "stopLoss",
        key: "stopLoss",
        width: 110,
        align: "center",
        responsive: ["lg"], // hide on small and medium devices

        render: (text, record) =>
          record?.stopLossPercent ? (
            <span className={styles.tableContent} onClick={openPaymentModal}>
              {!isServiceActive ? (
                <HideContentIcon />
              ) : isPercent ? (
                `${formatPrice(record?.stopLossPercent, 2)}%`
              ) : (
                `$${formatPrice(text, 3)}`
              )}
            </span>
          ) : (
            ""
          ),
      },
      {
        title: (
          <ColumnTitle
            title={
              <Tooltip
                title={"Targeted level for securing trading gains"}
                placement={"top"}
              >
                <span className={styles.headerTitle}>
                  {/*<FaChartLine />*/}
                  Take Profit
                </span>
              </Tooltip>
            }
            mode={isPercent}
            toggleMode={setIsPercent}
          />
        ),
        dataIndex: "takeProfit",
        key: "takeProfit",
        // sorter: (a, b) => a.takeProfit - b.takeProfit,
        width: 100,
        align: "center",
        responsive: ["md"], // hide on small and medium devices

        render: (text, record) =>
          text ? (
            <span className={styles.tableContent} onClick={openPaymentModal}>
              {!isServiceActive ? (
                <HideContentIcon />
              ) : isPercent ? (
                `${formatPrice(record?.takeProfitPercent, 2)}%`
              ) : (
                `$${formatPrice(text, 3)}`
              )}
            </span>
          ) : (
            ""
          ),
      },
      {
        title: (
          <span
            className={styles.headerTitle}
            title={"Anticipated profit or loss percentage from the trade"}
          >
            {/*<MdOutlineKeyboardReturn />*/}
            {window.innerWidth > 768 ? "Recent Return" : "ROI"}
          </span>
        ),
        dataIndex: "expectedReturn",
        key: "expectedReturn",
        sorter: (a, b) => a.expectedReturn - b.expectedReturn,
        width: 80,
        align: "center",
        responsive: ["xs", "sm"], // show only on small devices

        render: (text) =>
          text ? (
            <span className={styles.tableContent}>
              {Number(text).toFixed(2)}%
            </span>
          ) : (
            ""
          ),
      },
      {
        title: (
          <span
            className={styles.headerTitle}
            title={
              "Confidence level in the accuracy of the provided trading recommendation"
            }
          >
            Strength
          </span>
        ),
        dataIndex: "SIGNAL_STRENGTH",
        key: "SIGNAL_STRENGTH",
        sorter: (a, b) => a.SIGNAL_STRENGTH - b.SIGNAL_STRENGTH,
        width: 70,
        align: "center",
        responsive: ["xs", "sm"], // hide on small and medium devices

        render: (text) =>
          text ? (
            <span className={styles.tableContent}>
              <SignalStrengthUi level={text} />
            </span>
          ) : (
            ""
          ),
      },
    ];
  }, [
    aiMarketType,
    getTradeAssistantLoading,
    handleUpdatePrice,
    isPercent,
    isServiceActive,
    openPaymentModal,
    priceRefreshTime,
    setTradeHandler,
    tableData,
  ]);

  useEffect(() => {
    if (data && data.length) {
      setTableData(
        customSort([...data, ...allTradeSuggestionData])?.map((item, index) => {
          return {
            key: index,
            marketType: item?.MARKET_TYPE || "",
            coin: item,
            expectedReturn: item?.EXPECTED_RETURN || "",
            type: item,
            SIGNAL_STRENGTH: item?.SIGNAL_STRENGTH || "",
            buyNow: item,
            category: item?.CATEGORY_INFO.category || "",
            currentPrice: item?.CURRENT_PRICE || "",
            stopLoss: item?.STOP_LOSS || "",
            takeProfit: item?.TAKE_PROFIT || "",
            details: item,
            stopLossPercent: item?.STOP_LOSS_PERCENT * stopLossCFC || "",
            takeProfitPercent: item?.TAKE_PROFIT_PERCENT * takeProfitCFC || "",
            liveSuggestion: item,
          };
        }),
      );
    }
  }, [data, allTradeSuggestionData]);

  useEffect(() => {
    if (tableData && tableData.length && aiMarketType === "crypto") {
      tableData.map((item) => symbols.push(item?.coin?.SYMBOL_NAME));
      refreshInterval.current = setInterval(async () => {
        await axios
          .post(
            "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
            {
              platform_mode: "trade_assistant",
              user: userData?.["custom:custom_username"],
              email: userData?.["cognito:username"],
              request_type: "get_price_bulk",
              market_type: "crypto",
              symbol_names: symbols,
            },
          )
          .then((res) => {
            const priceData = res?.data?.PRICE_LIST;
            const priceList = [];
            priceData.map((item) => priceList.push(item?.PRICE));

            // UPDATE PRICES
            setTableData((prevData) => {
              return prevData.map((item, i) => {
                const {
                  buyNow: { TRADE_TYPE: tradeType },
                } = item;
                const priceColor =
                  item?.currentPrice < priceList[i] ? "#70AF85" : "#EF4B4B";

                return {
                  ...item,
                  currentPrice: priceList[i],
                  stopLoss: refreshLimit(
                    item.stopLossPercent,
                    priceList[i],
                    tradeType,
                    "stopLoss",
                  ),
                  takeProfit: refreshLimit(
                    item.takeProfitPercent,
                    priceList[i],
                    tradeType,
                    "takeProfit",
                  ),
                  priceColor,
                };
              });
            });
            setPriceRefreshTime(60);
          })
          .catch((err) => {
            console.log(err);
          });
        // console.log(tableData);
      }, 60 * 1000);

      return () => {
        if (refreshInterval.current) {
          clearInterval(refreshInterval.current);
        }
      };
    }
  }, [tableData, userData, aiMarketType]);

  useEffect(() => {
    if (tableData && tableData.length && aiMarketType === "crypto") {
      countdownInterval.current = setInterval(() => {
        if (priceRefreshTime > 0)
          setPriceRefreshTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current);
      }
    };
  }, [priceRefreshTime, tableData, aiMarketType]);

  useEffect(() => {
    if (getTradeAssistantLoading) {
      clearInterval(refreshInterval.current);
      clearInterval(countdownInterval.current);
    }

    if (!getTradeAssistantLoading && tradeAssistant?.ASSISTANT_SUGGESTIONS) {
      setPriceRefreshTime(60);
    }
  }, [getTradeAssistantLoading, tradeAssistant]);

  useEffect(() => {
    if (tradeAssistant.minutes_remaining) {
      setRemainingMinutes(+minutes_remaining);
      setPassedMinutes(+minutes_passed);
    }
  }, [tradeAssistant]);

  // create a ref variable to store the interval ID, and initialize it with null
  const intervalRef = useRef(null);

  // use the useEffect hook to set up and clear the interval when the component mounts and unmounts
  useEffect(() => {
    // set up the interval to run every minute (60000 milliseconds)
    if (remainingMinutes > 0) {
      intervalRef.current = setInterval(() => {
        // decrement the remaining minutes state variable by one
        setRemainingMinutes((prev) => prev - 1);
        // increment the passed minutes state variable by one
        setPassedMinutes((prev) => prev + 1);
      }, 60000);
    }

    // return a cleanup function to clear the interval when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [remainingMinutes, passedMinutes]);

  // use useEffect hook to check if the remaining minutes reaches zero
  useEffect(() => {
    // if it reaches zero, clear the interval and dispatch the getTradeAssistant action
    if (remainingMinutes === 0) {
      clearInterval(intervalRef.current);
    }
  }, [remainingMinutes]);

  // refresh live suggestion every 15 minutes
  useEffect(() => {
    if (!liveSuggestionDate || !suggestions?.length) return;

    // Only execute if the date matches and we haven't processed this timestamp yet
    if (
      liveSuggestionDate === "15 minutes ago" &&
      lastProcessedTimestamp.current !== liveSuggestionDate
    ) {
      suggestions.forEach((s) => {
        dispatch(
          getLiveSuggestion(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            s?.exchange_id || "kucoin",
            s.symbol,
          ),
        );
      });

      lastProcessedTimestamp.current = getRelativeTime(
        suggestions[0]?.signal_timestamp,
      ); // Store last processed timestamp
    }
  }, [dispatch, liveSuggestionDate, suggestions]);

  // useEffect(() => {
  //   dispatch(getLiveSuggestion("kucoin", "XMR/USDT"));
  // }, []);

  return (
    <>
      <div className={styles.tableOptions}>
        <div className={`${styles.countdown} ${styles.desktopVersion}`}>
          {remainingMinutes === 0 &&
          !getTradeAssistantLoading &&
          tradeAssistant?.minutes_remaining ? (
            // show a message that says "refresh to get new suggestions" here
            <strong style={{ width: "100%", textAlign: "center" }}>
              <img
                style={{ width: "30px", marginRight: ".3rem" }}
                src={refreshIcon}
                alt="refresh"
              />
              Refresh to get new suggestions
            </strong>
          ) : (
            <>
              <p style={{ marginBottom: 0, display: "flex", gap: ".3rem" }}>
                <span
                  style={{
                    color: "black",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: ".2rem",
                  }}
                >
                  <MdOutlineUpdate size={16} />
                  Next signal:
                </span>
                <span>{remainingMinutes || 0} mins</span>
              </p>
            </>
          )}
        </div>

        <AiAssistantTableActionButtons />
      </div>

      <Table
        columns={columns}
        dataSource={[
          { coin: "otherCoin", name: "Not Expandable", key: "otherCoin" },
          ...tableData,
        ]}
        scroll={{ y: 700 }}
        pagination
        bordered
        className={styles.table}
        loading={getTradeAssistantLoading}
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })}
        expandable={{
          expandedRowRender: (record) => (
            <ExpandedRowTradeAssistantTable data={record.details} />
          ),
          rowExpandable: (record) => record.name !== "Not Expandable",
          expandedRowKeys: expandedRowKeys,

          expandIcon: ({ expanded, onExpand, record }) => {
            if (record?.name === "Not Expandable") {
              return "";
            } else if (expanded) {
              return (
                <IoChevronUp
                  className={styles.expandIcon}
                  onClick={(e) => {
                    onRowClick(record);
                  }}
                />
              );
            } else {
              return (
                <IoChevronDown
                  className={styles.expandIcon}
                  onClick={(e) => {
                    onRowClick(record);
                  }}
                />
              );
            }
          },
        }}
      />
      <TradeModal setShow={setShowTradeModal} show={showTradeModal} />

      <AIAssistantServiceActivationModal
        showActivationModal={showServiceActivationModal}
        setShowActivationModal={setShowServiceActivationModal}
      />

      {showLiveSuggestionModal && (
        <LiveSuggestionModal
          open={showLiveSuggestionModal}
          onClose={() => {
            setShowLiveSuggestionModal(false);
            setLiveSuggestionTarget(null);
          }}
          coin={liveSuggestionTarget}
        />
      )}
    </>
  );
};

export default TradeAssistantTable;
