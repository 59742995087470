import React from "react";
import Chart from "react-apexcharts";
import moment from "moment";

const EquityChart = ({ data }) => {
  const series = [
    {
      name: "Equity",
      data: data.downsampled_timestamps.map((timestamp, index) => ({
        x: moment(timestamp).format("YYYY-MM-DD HH:mm"),
        y: data.downsampled_adv_equity[index]?.toFixed(2),
      })),
    },
  ];

  const options = {
    chart: {
      type: "line",
      height: 350,
      zoom: { enabled: true },
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "HH:mm",
      },
    },
    yaxis: {
      title: {
        text: "Equity Value",
      },
    },
    tooltip: {
      x: {
        format: "yyyy-MM-dd HH:mm",
      },
    },
  };

  return <Chart options={options} series={series} type="line" height={280} />;
};

export default EquityChart;
