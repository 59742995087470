import LineChart from "../../charts/trading-assistant/LineChart";
import PieChart from "../../charts/trading-assistant/PieChart";
import styles from "../../../styles/Components/integration/portfolio/ExchangePortfolioCharts.module.css";

function ExchangePortfolioCharts({
  portfolioLoading,
  portfolioData,
  balanceChartStatus,
  balanceChartData,
}) {
  return (
    <div className={styles.container}>
      <PieChart chartData={portfolioData} loading={portfolioLoading} />
      <LineChart
        data={balanceChartData?.BALANCE_CHART}
        loading={balanceChartStatus === "loading"}
      />
    </div>
  );
}

export default ExchangePortfolioCharts;
