import React from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/Components/trade-assistant/portfolio/VirtualPortfolioInfo.module.css";
import { Spin } from "antd";
import { numFormatter } from "../../trading-assistant/portfolio/VirtualPortfolioInfo";

function ExchangeBalanceInfo({ balanceChartStatus, balanceChartData }) {
  // const {
  //   exchangeState: { balanceChartStatus, balanceChartData },
  // } = useSelector((state) => state);
  const isLoading = balanceChartStatus === "loading";

  return (
    <ul className={styles.container}>
      <li className={styles.infoBox}>
        <span>Balance Change 24H:</span>
        <span>
          {isLoading ? (
            <Spin size={"small"} />
          ) : balanceChartData?.BALANCE_CHANGE_USD_24H ? (
            `${numFormatter(
              balanceChartData?.BALANCE_CHANGE_USD_24H,
              false,
              true,
            )} (${numFormatter(
              balanceChartData?.BALANCE_CHANGE_PERCENTAGE_24H,
              true,
              false,
            )})`
          ) : (
            0
          )}
        </span>
      </li>
      <li className={styles.infoBox}>
        <span>Balance Change 7D:</span>
        <span>
          {isLoading ? (
            <Spin size={"small"} />
          ) : balanceChartData?.BALANCE_CHANGE_USD_7D ? (
            `${numFormatter(
              balanceChartData?.BALANCE_CHANGE_USD_7D,
              false,
              true,
            )} (${numFormatter(
              balanceChartData?.BALANCE_CHANGE_PERCENTAGE_7D,
              true,
              false,
            )})`
          ) : (
            0
          )}
        </span>
      </li>
    </ul>
  );
}

export default ExchangeBalanceInfo;
