import { useEffect } from "react";

function UseDeleteLocalStorageItemOnRefresh(keys) {
  useEffect(() => {
    const handleWindowRefresh = () => {
      keys.map((key) => (key ? localStorage.removeItem(key) : null));
    };

    // Add the event listener for the 'beforeunload' event
    window.addEventListener("beforeunload", handleWindowRefresh);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleWindowRefresh);
    };
  }, [keys]);

  return null;
}

export default UseDeleteLocalStorageItemOnRefresh;
