import styles from "../../../../styles/shared/trade-modal/Step.module.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { setVirtualSelectedPortfolio } from "../../../../redux/trade/actions";
import UnlinkFromExchange from "../../../link-with-exchange/UnlinkFromExchange";
import Loader from "../Loader";

function VirtualPortfolioOptions() {
  const {
    profile: { profileData },
    tradeState: { virtualSelectedPortfolio, virtualPortfolioLoading },
    exchangeState: { unlinkFromExchangeLoading },
  } = useSelector((state) => state);

  const isFirstPortfolioConnected =
    profileData?.portfolio1_linked_exchange &&
    profileData?.portfolio1_linked_exchange !== "None";
  const isSecondPortfolioConnected =
    profileData?.portfolio2_linked_exchange &&
    profileData?.portfolio2_linked_exchange !== "None";

  const dispatch = useDispatch();

  const changePortfolioHandler = (portfolio) => {
    if (virtualPortfolioLoading) return;
    if (
      (isFirstPortfolioConnected && portfolio === "Primary") ||
      (isSecondPortfolioConnected && portfolio === "Secondary")
    ) {
      return message.info(
        " Sorry, you can't do paper trading with the linked portfolio with exchange.",
      );
    }

    dispatch(setVirtualSelectedPortfolio(portfolio));
  };

  useEffect(() => {
    if (!isFirstPortfolioConnected) {
      dispatch(setVirtualSelectedPortfolio("Primary"));
      return;
    }

    if (!isSecondPortfolioConnected) {
      dispatch(setVirtualSelectedPortfolio("Secondary"));
    }
  }, [profileData]);

  return (
    <div>
      <ul className={styles.options}>
        <li
          className={`${styles.option} ${
            virtualSelectedPortfolio === "Primary" ? styles.selected : ""
          } ${
            virtualPortfolioLoading && virtualSelectedPortfolio !== "Primary"
              ? styles.inactive
              : ""
          } ${isFirstPortfolioConnected ? styles.inactive : ""} ${
            isFirstPortfolioConnected ? styles.linked : ""
          }`}
          onClick={() => changePortfolioHandler("Primary")}
        >
          First
        </li>
        <li
          className={`${styles.option} ${
            virtualSelectedPortfolio === "Secondary" ? styles.selected : ""
          } ${
            virtualPortfolioLoading && virtualSelectedPortfolio !== "Secondary"
              ? styles.inactive
              : ""
          } ${isSecondPortfolioConnected ? styles.inactive : ""} ${
            isSecondPortfolioConnected ? styles.linked : ""
          }`}
          onClick={() => changePortfolioHandler("Secondary")}
        >
          Second
        </li>
      </ul>

      {/*{isFirstPortfolioConnected && !unlinkFromExchangeLoading && (*/}
      {/*  <UnlinkFromExchange*/}
      {/*    portfolio={"primary"}*/}
      {/*    btnText={"Unlink portfolio #1 from exchange."}*/}
      {/*    className={styles.unlinkBtn}*/}
      {/*  />*/}
      {/*)}*/}

      {/*{isSecondPortfolioConnected && !unlinkFromExchangeLoading && (*/}
      {/*  <UnlinkFromExchange*/}
      {/*    portfolio={"secondary"}*/}
      {/*    btnText={"Unlink portfolio #2 from exchange."}*/}
      {/*    className={styles.unlinkBtn}*/}
      {/*  />*/}
      {/*)}*/}

      {unlinkFromExchangeLoading && (
        <Loader text={"Detaching from exchange, please wait..."} />
      )}
    </div>
  );
}

export default VirtualPortfolioOptions;
