import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetList, getExchangesInfo } from "../../redux/exchange/actions";
import { IoMdArrowDropright } from "react-icons/io";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaPlus } from "react-icons/fa";
import { Button, Empty, Modal, Select } from "antd";

import styles from "../../styles/shared/other-coins-signal/OtherCoins.module.css";
import { Link } from "react-router-dom";
import { analyzeOtherCoins } from "../../redux/analyze-other-coins/otherCoinsAnalyzeSlice";
import SelectExchange from "../SelectExchange";

function OtherCoins({ btnTitle, disabledCoins = [] }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <span className={styles.button} onClick={() => setOpenModal(true)}>
        {btnTitle || <FaPlus />}
      </span>

      {openModal && (
        <OtherCoinsModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          disabledCoins={disabledCoins}
        />
      )}
    </>
  );
}

export default OtherCoins;

function OtherCoinsModal({ open, onClose, disabledCoins }) {
  const {
    exchangeState: { assetsLoading, assetsList },
    otherCoinsAnalyze: { analyze, status, errorMessage },
    main: { userData },
    tradingAssistant: { aiMarketType },
  } = useSelector((state) => state);

  const [selectedExchange, setSelectedExchange] = useState("");
  const [selectedSymbols, setSelectedSymbol] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const symbolsInputRef = useRef(null);

  const dispatch = useDispatch();

  const assets = assetsList?.filter((asset) => {
    const baseCurrency = asset?.split("/")?.[0];

    return !disabledCoins?.includes(baseCurrency?.toUpperCase());
  });

  const closeModal = () => {
    if (status === "loading") return;

    setSelectedExchange("");
    setSelectedSymbol([]);
    setIsDone(false);
    onClose();
  };

  const handleExchangeChange = (exchange) => {
    setSelectedExchange(exchange);
    dispatch(
      getAssetList(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        exchange,
      ),
    );
    setSelectedSymbol([]);
  };

  const handleSymbolChange = (value) => {
    setSelectedSymbol(value);
  };

  const handleSubmit = () => {
    if (status === "loading") return;
    setIsDone(true);

    const coins = selectedSymbols.map((symbol) => symbol.split("/USDT")[0]);

    return dispatch(
      analyzeOtherCoins(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        aiMarketType,
        coins,
        selectedExchange,
      ),
    );
  };

  // automatically close the modal when the analyze finished or failed
  useEffect(() => {
    if (
      status !== "loading" &&
      (analyze?.length || status === "error") &&
      isDone
    ) {
      closeModal();
    }
  }, [analyze?.length, closeModal, isDone, status]);

  return (
    <Modal
      open={open}
      onCancel={closeModal}
      centered
      footer={null}
      title="Get other coins signal"
      maskClosable={false}
    >
      <div className={styles.box}>
        <h4>
          <IoMdArrowDropright />
          Select an Exchange
        </h4>
        <SelectExchange
          value={selectedExchange}
          onChange={handleExchangeChange}
          loading={status === "loading"}
          style={{ width: "100%" }}
        />
        {/*<Select*/}
        {/*  style={{ width: "100%" }}*/}
        {/*  placeholder={*/}
        {/*    integratedDataLoading ? "Loading..." : "Select an exchange"*/}
        {/*  }*/}
        {/*  value={selectedExchange}*/}
        {/*  onChange={handleExchangeChange}*/}
        {/*  disabled={exchangesLoading}*/}
        {/*>*/}
        {/*  {exchanges?.map((exchange) => (*/}
        {/*    <Select.Option*/}
        {/*      key={exchange.id}*/}
        {/*      value={exchange.id}*/}
        {/*      className={styles.option}*/}
        {/*    >*/}
        {/*      <LazyLoadImage*/}
        {/*        src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${exchange.id}.svg`}*/}
        {/*        alt={exchange.name}*/}
        {/*        width="25px"*/}
        {/*        height="25px"*/}
        {/*        effect="blur"*/}
        {/*      />*/}
        {/*      {exchange.name}*/}
        {/*    </Select.Option>*/}
        {/*  ))}*/}
        {/*</Select>*/}
      </div>

      <div className={styles.box}>
        <h4>
          <IoMdArrowDropright />
          Select a Coin
        </h4>
        <Select
          style={{ width: "100%" }}
          ref={symbolsInputRef}
          placeholder="Select a coin"
          value={selectedSymbols}
          onChange={handleSymbolChange}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }
          mode="multiple"
          // options={symbolOptions}
          disabled={
            !selectedExchange ||
            (selectedExchange && !assetsLoading && assetsList.length === 0)
          }
        >
          {assetsLoading ? (
            <Select.Option disabled>Loading...</Select.Option>
          ) : assetsList?.length > 0 ? (
            assets?.map((asset) => (
              <Select.Option
                key={asset}
                value={asset}
                className={styles.option}
              >
                {asset}
              </Select.Option>
            ))
          ) : (
            <Select.Option disabled className={styles.emptyOption}>
              <Empty imageStyle={{ width: "80px", height: "60px" }} />
            </Select.Option>
          )}
        </Select>

        {selectedExchange && !assetsLoading && assetsList.length === 0 && (
          <p className={styles.errorMsg}>
            You need o <Link to="/integration">integrate</Link> with this
            exchange first!
          </p>
        )}
      </div>

      <Button
        onClick={handleSubmit}
        loading={status === "loading"}
        className={styles.submitButton}
        disabled={!selectedSymbols.length || !selectedExchange}
      >
        Confirm
      </Button>
    </Modal>
  );
}
