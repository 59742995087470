import axios from "axios";
import { axiosErrorHandler } from "../../utility/action-helper";

const baseUrl =
  "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api";

const testExchangeInitialState = {
  portfolio: [],
  portfolioStatus: "",
  portfolioErrorMessage: "",
  portfolioFetched: false,
  tradeHistory: [],
  tradeHistoryStatus: "",
  tradeHistoryErrorMessage: "",
  tradeHistoryFetched: false,
  comprehensiveTradeHistory: [],
  comprehensiveTradeHistoryStatus: "",
  comprehensiveTradeHistoryErrorMessage: "",
  comprehensiveFetched: false,
  balanceChartStatus: "",
  balanceChartData: {},
  balanceChartError: "",
  balanceChartFetched: false,
};

export const testExchangeReducer = (
  state = testExchangeInitialState,
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case "get-test-exchange-portfolio-request":
      return {
        ...state,
        portfolioStatus: "loading",
        portfolioErrorMessage: "",
      };
    case "get-test-exchange-portfolio-success":
      return {
        ...state,
        portfolioStatus: "success",
        portfolio: [...payload],
        portfolioFetched: true,
      };
    case "get-test-exchange-portfolio-error":
      return {
        ...state,
        portfolioStatus: "error",
        portfolioErrorMessage: payload,
      };

    case "get-test-exchange-trade-history-request":
      return {
        ...state,
        tradeHistoryStatus: "loading",
        tradeHistoryErrorMessage: "",
      };

    case "get-test-exchange-trade-history-success":
      return {
        ...state,
        tradeHistoryStatus: "success",
        tradeHistory: [...payload],
        tradeHistoryFetched: true,
      };

    case "get-test-exchange-trade-history-error":
      return {
        ...state,
        tradeHistoryStatus: "error",
      };

    case "get-test-exchange-comprehensive-trade-history-request":
      return {
        ...state,
        comprehensiveTradeHistoryStatus: "loading",
        comprehensiveTradeHistoryErrorMessage: "",
      };

    case "get-test-exchange-comprehensive-trade-history-success":
      return {
        ...state,
        comprehensiveTradeHistoryStatus: "success",
        comprehensiveTradeHistory: [...payload],
        comprehensiveFetched: true,
      };

    case "get-test-exchange-comprehensive-trade-history-error":
      return {
        ...state,
        comprehensiveTradeHistoryErrorMessage: "error",
      };

    case "get-test-exchange-balance-chart-request":
      return {
        ...state,
        // balanceChartData: {},
        balanceChartStatus: "loading",
        balanceChartError: "",
      };
    case "get-test-exchange-balance-chart-success":
      return {
        ...state,
        balanceChartStatus: "done",
        balanceChartData: payload,
        balanceChartFetched: true,
      };
    case "get-test-exchange-balance-chart-error":
      return {
        ...state,
        balanceChartStatus: "error",
        balanceChartError: payload,
      };

    default:
      return state;
  }
};

export const getTestExchangePortfolio =
  (user, email, exchange_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: "get-test-exchange-portfolio-request" });

    const data = {
      mode: "get_portfolio",
      user,
      email,
      exchange_id,
    };

    if (retryCount > 0) {
      data.use_more_caching = "True";
    }

    await axios
      .post(baseUrl, data)
      .then((res) => {
        dispatch({
          type: "get-test-exchange-portfolio-success",
          payload: res.data?.portfolio,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getTestExchangePortfolio(user, email, exchange_id, retryCount + 1),
          "get-test-exchange-portfolio-error",
          user,
          email,
          "get_portfolio",
        );
      });
  };

export const getTestExchangeTradeHistory =
  (user, email, exchange_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: "get-test-exchange-trade-history-request" });

    const data = {
      mode: "get_exchange_trade_history",
      user,
      email,
      exchange_id,
    };

    await axios
      .post(baseUrl, data)
      .then((res) => {
        dispatch({
          type: "get-test-exchange-trade-history-success",
          payload: res.data,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getTestExchangeTradeHistory(
              user,
              email,
              exchange_id,
              retryCount + 1,
            ),
          "get-test-exchange-trade-history-error",
          user,
          email,
          "get_exchange_trade_history",
        );
      });
  };

export const getTestExchangeComprehensiveTradeHistory =
  (user, email, exchange_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: "get-test-exchange-comprehensive-trade-history-request" });

    const data = {
      mode: "get_exchange_trade_history_comphrensive",
      user,
      email,
      exchange_id,
    };

    await axios
      .post(baseUrl, data)
      .then((res) => {
        dispatch({
          type: "get-test-exchange-comprehensive-trade-history-success",
          payload: res.data,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getTestExchangeComprehensiveTradeHistory(
              user,
              email,
              exchange_id,
              retryCount + 1,
            ),
          "get-test-exchange-comprehensive-trade-history-error",
          user,
          email,
          "get_exchange_trade_history_comphrensive",
        );
      });
  };

export const getTestExchangeBalanceChart =
  (user, email, exchange_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: "get-test-exchange-balance-chart-request" });

    await axios
      .post(
        "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          mode: "get_balance_chart",
          user,
          email,
          exchange_id,
        },
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "get-test-exchange-balance-chart-success",
          payload: res?.data,
        });
      })
      .catch((err) => {
        console.log(err);
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getTestExchangeBalanceChart(
              user,
              email,
              exchange_id,
              retryCount + 1,
            ),
          "get-test-exchange-balance-chart-error",
          user,
          email,
          "exchange/get_balance_chart",
        );
      });
  };
