import { useEffect, useRef, useState } from "react";
import { Tabs, Tour } from "antd";
import { useSelector } from "react-redux";
import TabPane from "antd/lib/tabs/TabPane";
import "../../styles/antdTabs.css";
import { ImTable } from "react-icons/im";
import { TbChartPieFilled } from "react-icons/tb";
import ExchangesAndPaperTradingTab from "../../shared/exchanges-papertrading-tabs/ExchangesAndPaperTradingTab";
import VirtualPortfolioTableActionButtons from "./portfolio/VirtualPortfolioTableActionButtons";
import { FaChartColumn } from "react-icons/fa6";
import TestExchanges from "./TestExchanges";
import ExchangeNewPortfolioTable from "../Integration/portfolio-section/ExchangeNewPortfolioTable";
import ExchangeBalanceInfo from "../Integration/portfolio-section/ExchangeBalanceInfo";
import ExchangePortfolioCharts from "../Integration/portfolio-section/ExchangePortfolioCharts";

// styles
import styles from "../../styles/pages/trading-assistant/index.module.sass";

function Portfolio({ portfolio }) {
  const {
    testExchange: {
      portfolio: exchangePortfolio,
      portfolioStatus,
      balanceChartStatus,
      balanceChartData,
    },
    exchangeState: { integratedDataLoading },
  } = useSelector((state) => state);

  const [showTour, setShowTour] = useState(false);
  const [activeTab, setActiveTab] = useState("table");
  const portfolioChartsRef = useRef(null);

  const tourSteps = [
    {
      title: <FaChartColumn color={"#0C4CFC"} size={18} />,
      description: (
        <p
          style={{
            marginTop: "-16px",
            fontSize: "16px",
            marginBottom: 0,
            color: "#2B303A",
          }}
        >
          View portfolio chart here.
        </p>
      ),
      target: () => portfolioChartsRef.current,
    },
  ];

  useEffect(() => {
    setShowTour(true);
  }, []);

  return (
    <div>
      <div className={styles.tradingHeader}>
        <div>
          {" "}
          <h3>
            <strong>My Portfolio</strong>
          </h3>
        </div>

        <ExchangesAndPaperTradingTab
          activeTab={"paper"}
          pageType={"portfolio"}
        />
        <TestExchanges style={{ marginTop: 0 }} />
      </div>

      <div className={styles.portfolioTable}>
        <VirtualPortfolioTableActionButtons />
        <Tabs
          type={"card"}
          activeKey={activeTab}
          onChange={(key) => {
            if (showTour && key === "chart") setShowTour(false);
            setActiveTab(key);
          }}
          className={`${styles.tabs} custom-tabs`}
          animated
        >
          <TabPane
            tab={
              <span>
                <ImTable size={18} /> Portfolio Table
              </span>
            }
            key={"table"}
          >
            <ExchangeNewPortfolioTable
              data={exchangePortfolio}
              exchange="cryptocrispy"
              loading={portfolioStatus === "loading" || integratedDataLoading}
            />
          </TabPane>

          <TabPane
            tab={
              <span ref={portfolioChartsRef} onClick={() => setShowTour(false)}>
                <TbChartPieFilled size={18} />
                Portfolio Charts
              </span>
            }
            key={"chart"}
          >
            <ExchangeBalanceInfo
              balanceChartStatus={balanceChartStatus}
              balanceChartData={balanceChartData}
            />
            <ExchangePortfolioCharts
              balanceChartData={balanceChartData}
              balanceChartStatus={balanceChartStatus}
              portfolioData={exchangePortfolio}
              portfolioLoading={portfolioStatus === "loading"}
            />
          </TabPane>
        </Tabs>
      </div>
      {showTour && (
        <Tour
          animated
          onClose={() => setShowTour(false)}
          steps={tourSteps}
          isOpen={showTour}
        />
      )}
    </div>
  );
}

export default Portfolio;
