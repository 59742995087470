import React, { useEffect, useState } from "react";
import { updateExchangeHistory } from "../../../redux/exchange/actions";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/Components/integration/portfolio/PortfolioTable.module.css";
import { Table, Tooltip } from "antd";
import { currencyFormatter, formatAmount } from "../../../utility/helper";
import moment from "moment";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { getRelativeTime } from "../../../utility/formatter-helper";
import ExchangeHistoryTableExpandedRow from "./ExchangeHistoryTableExpandedRow";

function ExchangeHistoryTable({ data, loading, exchange }) {
  const {
    main: { userData },
    exchangeState: {
      // exchangeHistory,
      // exchangeHistoryLoading,
      // selectedPortfolioExchange,
      // isMaximumHistory,
    },
  } = useSelector((state) => state);

  const [tableData, setTableData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize] = useState(200); // Set your page size here

  const sortedHistory = data?.sort((a, b) =>
    moment(b?.create_date, "YYYY-MM-DD HH:mm:ss.SSSSSS").diff(
      moment(a?.create_date, "YYYY-MM-DD HH:mm:ss.SSSSSS"),
    ),
  );

  const onRowClick = (record) => {
    if (expandedRowKeys.includes(record.key)) {
      setExpandedRowKeys([]);
      return;
    }
    setExpandedRowKeys([record.key]);
  };

  const dispatch = useDispatch();

  const columns = [
    {
      title: <span className={styles.title}>Asset</span>,
      dataIndex: "asset",
      key: "asset",
      align: "center",
      width: 60,
      render: (text) => (
        <span className={styles.tableContent}>
          <img
            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${text}.png`}
            alt={text}
            className={styles.coinIcon}
          />
          {text}
        </span>
      ),
    },
    {
      title: <span className={styles.title}>Currency</span>,
      dataIndex: "currency",
      key: "currency",
      width: 55,
      responsive: ["lg"],
      align: "center",
      render: (text) => (
        <span className={`${styles.tableContent}`}>{text || "-"}</span>
      ),
    },
    {
      title: <span className={styles.title}>Amount</span>,
      dataIndex: "amount",
      key: "amount",
      width: 60,
      responsive: ["xs", "sm"],
      align: "center",
      render: (text) => (
        <span
          // onClick={() => clickHandler(text)}
          className={`${styles.tableContent}`}
        >
          {formatAmount(text)}
        </span>
      ),
    },
    {
      title: <span className={styles.title}>Action</span>,
      dataIndex: "action",
      key: "action",
      width: 60,
      responsive: ["xs", "sm"],
      align: "center",
      render: (text) => (
        <span
          // onClick={() => clickHandler(text)}
          className={`${styles.tableContent} ${
            text === "Buy" ? styles.buyBtn : styles.sellBtn
          }`}
        >
          {text}
        </span>
      ),
    },
    {
      title: <span className={styles.title}>Order Mode</span>,
      dataIndex: "orderMode",
      key: "orderMode",
      width: 85,
      responsive: ["md"],
      align: "center",
      render: (text) => <span>{text}</span>,
    },
    {
      title: <span className={styles.title}>Trigger Price</span>,
      dataIndex: "price",
      key: "price",
      width: 85,
      responsive: ["lg"],
      align: "center",
      render: (text) => (
        <span
          // onClick={() => clickHandler(text)}
          className={`${styles.tableContent}`}
        >
          {parseFloat(text) ? currencyFormatter(text) : text}
        </span>
      ),
    },
    {
      title: <span className={styles.title}>Date</span>,
      dataIndex: "date",
      key: "date",
      width: 85,
      responsive: ["lg"],
      align: "center",
      render: (text) => (
        <span
          // onClick={() => clickHandler(text)}
          className={`${styles.tableContent}`}
        >
          {getRelativeTime(text)}
        </span>
      ),
    },
    {
      title: <span className={styles.title}>Trader</span>,
      dataIndex: "trader",
      key: "trader",
      width: 85,
      align: "center",
      render: (text) => (
        <span
          // onClick={() => clickHandler(text)}
          className={`${styles.tableContent}`}
        >
          {text.replace("_", " ")}
        </span>
      ),
    },
    {
      title: (
        <span className={styles.title}>
          {window.innerWidth > 768 ? "Trade Status" : "Status"}
        </span>
      ),
      dataIndex: "status",
      key: "status",
      width: 85,
      responsive: ["md"],
      align: "center",
      render: (text, record) => (
        <span
          // onClick={() => clickHandler(text)}
          className={`${styles.tableContent} ${styles.status}`}
        >
          <Tooltip
            title={record?.info?.order_message}
            color={
              record?.info?.order_message?.toLowerCase().includes("success")
                ? "green"
                : "red"
            }
          >
            {text}
            <IoMdInformationCircleOutline />
          </Tooltip>
        </span>
      ),
    },
  ];
  const handlePageChange = (page) => {
    // setCurrentPage(page);
    if (loading) return;
    // if (isMaximumHistory)
    //   return message.info(
    //     "You have reached the end of the history. All available data has been displayed.",
    //   );

    const latestItemDate =
      sortedHistory?.[sortedHistory?.length - 1]?.["Trade Date"];

    // Dispatch action to get more data based on the latest item's date
    if (userData?.email && exchange) {
      dispatch(
        updateExchangeHistory(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          exchange,
          moment(latestItemDate, "DD MMM YYYY, HH:mm:ss").format(
            "YYYY-MM-DD HH:mm:ss.SSSSSS",
          ), // Send the date of the latest item for pagination
        ),
      );
    }
  };

  useEffect(() => {
    // const sortedData = [...exchangeHistory].sort(
    //   (a, b) => new Date(b.create_date) - new Date(a.create_date),
    // );
    setTableData(
      sortedHistory?.map((item, index) => {
        return {
          key: index,
          asset: item?.asset_id,
          currency: item?.currency,
          amount: item?.amount,
          action: item?.trade_type,
          price: item?.trigger_price,
          date: item?.create_date,
          orderMode: item?.order_mode,
          trader: item?.trader,
          status: item?.trade_status,
          info: item,
        };
      }),
    );
  }, [data]);

  // useEffect(() => {
  //   if (userData?.email && selectedPortfolioExchange) {
  //     dispatch(
  //       getExchangeTradeHistory(
  //         userData?.["custom:custom_username"],
  //         userData?.["cognito:username"],
  //         selectedPortfolioExchange,
  //       ),
  //     );
  //   }
  // }, [dispatch, selectedPortfolioExchange, userData]);
  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={tableData}
        height={500}
        scroll={{ y: 500 }}
        className={`${styles.portfolioTable} expanded-table`}
        pagination={false}
        // pagination={{
        //   current: currentPage,
        //   pageSize,
        //   total: sortedHistory?.length,
        //   onChange: handlePageChange,
        // }}
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })}
        expandable={{
          expandedRowRender: (record) => (
            <ExchangeHistoryTableExpandedRow data={record} />
          ),
          expandedRowKeys,
          expandIcon: ({ expanded, onExpand, record }) => {
            if (expanded) {
              return (
                <IoChevronUp
                  className={styles.expandIcon}
                  onClick={(e) => {
                    onRowClick(record);
                  }}
                />
              );
            } else {
              return (
                <IoChevronDown
                  className={styles.expandIcon}
                  onClick={(e) => {
                    onRowClick(record);
                  }}
                />
              );
            }
          },
        }}
      />
      {/*{sortedHistory?.length > 0 && (*/}
      {/*  <div className={styles.showMoreBtn}>*/}
      {/*    <Button*/}
      {/*      onClick={handlePageChange}*/}
      {/*      loading={exchangeComprehensiveHistoryLoading}*/}
      {/*      style={{ opacity: `${isMaximumHistory ? 0.7 : 1}` }}*/}
      {/*    >*/}
      {/*      Show more*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
}

export default ExchangeHistoryTable;
