import styles from "../../../styles/Components/trade-assistant/assistant/AiAssistantTableActionButtons.module.css";
import ActionButtonsContainer from "../../../ui/ActionButtonsContainer";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Menu } from "antd";
import { GrDocumentCsv } from "react-icons/gr";
import { VscJson } from "react-icons/vsc";
import React from "react";
import useDownloader from "react-use-downloader";
import { TbFileExport } from "react-icons/tb";
import {
  getAllTradeSuggestion,
  getTradeAssistant,
  setAiAssistantMode,
  setAIMarketType,
} from "../../../redux/trading-assistant/actions";
import { FiFileText, FiRefreshCcw } from "react-icons/fi";
import {
  FaBalanceScale,
  FaBitcoin,
  FaChartLine,
  FaExclamationTriangle,
  FaLink,
  FaMoneyBillWave,
  FaShieldAlt,
} from "react-icons/fa";
import { BsExclamationTriangleFill, BsPeople } from "react-icons/bs";
import { HiRocketLaunch } from "react-icons/hi2";
import { AiTwotoneFire } from "react-icons/ai";
import { updateProfile } from "../../../redux/profile/actions";
import { BiCategory, BiMoney } from "react-icons/bi";
import { MdShowChart } from "react-icons/md";

function AiAssistantTableActionButtons() {
  const {
    profile: { profileData = {} },
    tradingAssistant: {
      tradeAssistant: { export_links } = {},
      getTradeAssistantLoading,
      aiMarketType,
      selectedPortfolio,
      aiAssistantMode,
    },
    main: { userData },
  } = useSelector((state) => state);

  const { download } = useDownloader();
  const dispatch = useDispatch();

  const isServiceActive = profileData?.myais_active === "True";

  const disabledStyle = {
    opacity: `${getTradeAssistantLoading ? 0.8 : 1}`,
    cursor: `${getTradeAssistantLoading ? "not-allowed" : "pointer"}`,
  };

  const handleMenuClick = (e) => {
    if (getTradeAssistantLoading) return;

    if (e.key === "0") {
      download(
        export_links?.csv_report,
        `csv_report-${new Date().toLocaleString()}.csv`,
      );
    }
    if (e.key === "1") {
      download(
        export_links?.json_report,
        `json_report-${new Date().toLocaleString()}`,
      );
    }
  };

  const handleRefresh = () => {
    if (getTradeAssistantLoading) return;
    dispatch(
      getTradeAssistant(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedPortfolio.toLowerCase(),
        aiMarketType,
        isServiceActive,
        aiAssistantMode,
      ),
    );
    dispatch(
      getAllTradeSuggestion(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        aiMarketType,
        isServiceActive,
      ),
    );
  };

  const handleRiskAppetite = (e) => {
    let attribute_names = ["ai_assistant_risk_appetite"];
    dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        attribute_names,
        [e.key],
      ),
    );
    dispatch(
      getTradeAssistant(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedPortfolio,
        aiMarketType,
        isServiceActive,
        aiAssistantMode,
      ),
    );
  };

  const handleChangeCategory = (e) => {
    dispatch(setAiAssistantMode(e.key));
    dispatch(
      getTradeAssistant(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedPortfolio,
        aiMarketType,
        isServiceActive,
        e.key,
      ),
    );
  };

  const handleChangeMarketType = (e) => {
    dispatch(setAIMarketType(e.key));
    dispatch(
      getTradeAssistant(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedPortfolio?.toLowerCase() || "primary",
        e.key,
        isServiceActive,
        aiAssistantMode,
      ),
    );
  };

  const exportMenu = {
    items: [
      {
        label: "CSV",
        key: "0",
        icon: <FiFileText />,
      },
      {
        label: "JSON",
        key: "1",
        icon: <VscJson />,
      },
    ],
    onClick: handleMenuClick,
  };

  const riskMenu = {
    items: [
      {
        label: "Very Cautious",
        key: "1",
        icon: <FaShieldAlt />,
      },
      {
        label: "Cautious",
        key: "2",
        icon: <BsExclamationTriangleFill />,
      },
      {
        label: "Balanced",
        key: "3",
        icon: <FaBalanceScale />,
      },
      {
        label: "Adventurous",
        key: "4",
        icon: <HiRocketLaunch />,
      },
      {
        label: "Very Adventurous",
        key: "5",
        icon: <AiTwotoneFire />,
      },
    ],
    onClick: handleRiskAppetite,
    defaultSelectedKeys: [profileData?.ai_assistant_risk_appetite || "1"],
  };

  const categoryMenu = {
    items: [
      {
        key: "null",
        label: "All Categories",
        icon: <BiCategory />, // General category icon
      },
      {
        key: "only_ai",
        label: "AI",
        icon: <AiTwotoneFire />, // Represents AI and innovation
      },
      {
        key: "only_social",
        label: "Social",
        icon: <BsPeople />, // Represents social networks
      },
      {
        key: "only_on_chain",
        label: "On Chain",
        icon: <FaLink />, // Represents blockchain and connectivity
      },
      {
        key: "only_ta",
        label: "Technical Indicators",
        icon: <MdShowChart />, // Represents charts and technical analysis
      },
    ],
    onClick: handleChangeCategory,
    defaultSelectedKeys: [aiAssistantMode || "null"],
  };

  const marketMenu = {
    items: [
      {
        label: "Crypto",
        key: "crypto",
        icon: <FaBitcoin />, // Represents cryptocurrency
      },
      {
        label: "Forex",
        key: "forex",
        icon: <FaMoneyBillWave />, // Represents currency exchange
      },
      {
        label: "Stock",
        key: "asx",
        icon: <FaChartLine />, // Represents stock market trends
        disabled: true,
      },
    ],
    onClick: handleChangeMarketType,
    defaultSelectedKeys: [aiMarketType || "null"],
  };

  return (
    <ActionButtonsContainer
      style={{ marginLeft: "auto", alignSelf: "flex-end" }}
    >
      {isServiceActive && (
        <Dropdown menu={exportMenu} trigger={["click"]}>
          <li className={styles.button} style={disabledStyle}>
            <span>
              <TbFileExport />
            </span>
          </li>
        </Dropdown>
      )}
      <li className={styles.button}>
        <Button loading={getTradeAssistantLoading} onClick={handleRefresh}>
          {!getTradeAssistantLoading && <FiRefreshCcw />}
        </Button>
      </li>
      <Dropdown
        menu={riskMenu}
        trigger={["click"]}
        disabled={getTradeAssistantLoading}
      >
        <li className={styles.button} style={disabledStyle}>
          <FaExclamationTriangle />
          <span className={styles.label}>Risk</span>
        </li>
      </Dropdown>
      <Dropdown
        menu={categoryMenu}
        trigger={["click"]}
        disabled={getTradeAssistantLoading}
      >
        <li className={styles.button} style={disabledStyle}>
          <BiCategory />
          <span className={styles.label}>Category</span>
        </li>
      </Dropdown>

      <Dropdown
        menu={marketMenu}
        trigger={["click"]}
        disabled={getTradeAssistantLoading}
      >
        <li className={styles.button} style={disabledStyle}>
          <BiMoney />
          <span className={styles.label}>Market type</span>
        </li>
      </Dropdown>
    </ActionButtonsContainer>
  );
}

export default AiAssistantTableActionButtons;
