import { Modal, Pagination, Spin, message, Empty } from "antd";
import styles from "../styles/ExchangesModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setExchangeForConnection,
  setPreviousPath,
} from "../redux/exchange/actions";
import { certificatedExchange } from "../utility/data";
import { LiaCertificateSolid } from "react-icons/lia";
import ExchangeRecommendationModal from "./exchanges/ExchangeRecommendationModal";
import { IoSearch } from "react-icons/io5";
import UpdateExchangeIpModal from "./exchange-ip-setting/UpdateExchangeIpModal";
import * as React from "react";

function ExchangesModal({ show, setShow }) {
  const {
    exchangeState: { exchangesInfo, exchangesLoading, integratedData },
  } = useSelector((state) => state);

  const itemsPerPage = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedExchangeName, setSelectedExchangeName] = useState("");
  const [selectedExchangeId, setSelectedExchangeId] = useState("");
  const [showRecommendationModal, setShowRecommendationModal] = useState(false);
  const [searchedExchange, setSearchedExchange] = useState("");
  const [selectedExchange, setSelectedExchange] = useState({});
  const [showIpSettingsModal, setShowIpSettingsModal] = useState(false);

  const exchanges = exchangesInfo?.all_name_list
    ?.map((ex) => (ex === "CryptoCrispy" ? "Test Exchange" : ex))
    ?.filter((item) =>
      item.toLowerCase().includes(searchedExchange.toLowerCase()),
    );

  const changeSearchValue = (e) => {
    setCurrentPage(1);
    setSearchedExchange(e.target.value.trimLeft());
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const goToConnectionPage = (name, id) => {
    navigate(`/integrate-exchange/${name}`);
    dispatch(setExchangeForConnection(id));
    dispatch(setPreviousPath(location.pathname));
  };

  const selectExchangeHandler = (e, i, name) => {
    if (e.target.closest("div")) {
      const exchangeId = exchangesInfo.all_id_list[startIndex + i];

      setSelectedExchangeId(exchangeId);
      setSelectedExchangeName(name);

      if (exchangeId === "cryptocrispy") {
        return Modal.info({
          content: <h3>This exchange has $1000 virtual money for testing.</h3>,
          centered: true,
        });
      }

      const isInWizard = location.pathname.includes("wizard");

      const exchangeData = integratedData?.find(
        (item) => item?.id === exchangeId,
      );

      if (exchangeData) {
        if (
          exchangeData?.status !== "Active" &&
          exchangeData?.ip_status !== "OK"
        ) {
          setSelectedExchange(exchangeData);
          setShowIpSettingsModal(true);
          return;
        }
      }

      if (!isInWizard) {
        if (exchangeData?.status === "Active") {
          setShowRecommendationModal(true);
        } else {
          goToConnectionPage(name, exchangesInfo.all_id_list[startIndex + i]);
        }
      } else {
        goToConnectionPage(name, exchangesInfo.all_id_list[startIndex + i]);
      }

      // if (!isInWizard) {
      //   if (exchangeData?.ip_status !== "OK") {
      //     setSelectedExchange(exchangeData);
      //     setShowIpSettingsModal(true);
      //     return;
      //   }
      //   if (exchangeData?.status === "Active") {
      //     setShowRecommendationModal(true);
      //   } else {
      //     goToConnectionPage(name, exchangesInfo.all_id_list[startIndex + i]);
      //   }
      // } else {
      //   goToConnectionPage(name, exchangesInfo.all_id_list[startIndex + i]);
      // }

      // if (
      //   integratedData?.find(
      //     (item) =>
      //       item?.id === exchangeId && item?.status === "Active" && !isInWizard,
      //   )
      // ) {
      //   setShowRecommendationModal(true);
      //   return;
      // }
      // navigate(`/integrate-exchange/${name}`);
      // dispatch(
      //     setExchangeForConnection(exchangesInfo.all_id_list[startIndex + i]),
      // );
      // dispatch(setPreviousPath(location.pathname));
      // goToConnectionPage(name, exchangesInfo.all_id_list[startIndex + i]);
    }
  };
  return (
    <Modal
      open={show}
      onCancel={() => {
        setShow(false);
        setCurrentPage(1);
      }}
      closable={false}
      footer={null}
      centered
      bodyStyle={{ minHeight: "300px" }}
      children={
        <div>
          <div className={styles.title}>
            <h3>Crypto Exchanges</h3>
            <p>
              The following crypto exchanges are officially supported by
              CryptoCrispy.
            </p>
          </div>
          <div className={styles.searchBox}>
            <input
              value={searchedExchange}
              onChange={changeSearchValue}
              placeholder={"Search Exchange"}
            />
            <IoSearch color={"gray"} />
          </div>
          <div className={styles.exchanges}>
            {exchangesLoading && (
              <div className={styles.loader}>
                <Spin tip="Loading..." />
              </div>
            )}
            {!exchangesLoading && Object.keys(exchangesInfo).length && (
              <>
                <div className={styles.exchangeList}>
                  {exchanges.length > 0 ? (
                    exchanges
                      .slice(startIndex, endIndex)
                      .map((exchangeName, i) => (
                        <div
                          className={`${styles.exchangeItem} ${
                            !!integratedData.find(
                              (item) => item.name === exchangeName,
                            )
                              ? styles.connected
                              : ""
                          } ${
                            !!integratedData.find(
                              (item) =>
                                item.name === exchangeName &&
                                item.status !== "Active",
                            )
                              ? styles.expired
                              : ""
                          } ${
                            !!integratedData.find(
                              (item) =>
                                item.name === exchangeName &&
                                item.status !== "Active" &&
                                item.ip_status !== "OK",
                            )
                              ? styles.ipRequired
                              : ""
                          }`}
                          id={exchangesInfo.all_id_list[startIndex + i]}
                          key={exchangesInfo.all_id_list[startIndex + i]}
                          onClick={(e) =>
                            selectExchangeHandler(e, i, exchangeName)
                          }
                        >
                          <img
                            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${
                              exchangesInfo.all_id_list[
                                i + 10 * (currentPage - 1)
                              ]
                            }.png`}
                            alt={exchangeName}
                            className={styles.exchangeImg}
                          />
                          <span className={styles.exchangeName}>
                            {exchangeName}
                          </span>
                          {certificatedExchange.includes(
                            exchangesInfo.all_id_list[
                              exchangesInfo.all_name_list.findIndex(
                                (item) => item === exchangeName,
                              )
                            ],
                          ) && (
                            <LiaCertificateSolid
                              title={"Certificated"}
                              className={styles.certificatedBadge}
                            />
                          )}
                        </div>
                      ))
                  ) : (
                    <div className={styles.emptyBox}>
                      <Empty description={"No Matched Exchange"} />
                    </div>
                  )}
                </div>
                {exchanges.length > 10 && (
                  <Pagination
                    className={styles.pagination}
                    current={currentPage}
                    total={exchanges.length}
                    pageSize={itemsPerPage}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                )}
              </>
            )}
          </div>

          <ExchangeRecommendationModal
            show={showRecommendationModal}
            setShow={setShowRecommendationModal}
            exchange={{ name: selectedExchangeName, id: selectedExchangeId }}
          />
          <UpdateExchangeIpModal
            show={showIpSettingsModal}
            setShow={setShowIpSettingsModal}
            exchangeData={selectedExchange}
          />
        </div>
      }
    />
  );
}

export default ExchangesModal;
