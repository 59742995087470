import { useState } from "react";
import { Empty, Modal, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getLiveSuggestion } from "../../redux/live-suggestion/liveSuggestionSlice";
import { getRelativeTime } from "../../utility/formatter-helper";
import { MdAccessTime, MdLightbulb, MdTrendingUp } from "react-icons/md";
import ErrorBox from "../../components/mini-app/shared/ErrorBox";
import EquityChart from "./EquityChart";
import SelectExchange from "../SelectExchange";

import styles from "../../styles/shared/live-suggestion/LiveSuggestionModal.module.css";
import AssetDisplay from "../../ui/AssetDisplay";
import TradesTable from "./TradesTable";
import { HiOutlineRefresh } from "react-icons/hi";

function InfoBox({ info }) {
  return (
    <ul className={styles.infoList}>
      <li className={styles.infoItem}>
        <span className={styles.infoTitle}>
          <MdLightbulb color="#f9ca24" size={18} /> Suggestion:
        </span>
        <span>{info?.prediction}</span>
      </li>
      <li className={styles.infoItem}>
        <span className={styles.infoTitle}>
          <MdTrendingUp color="#34a853" size={18} /> Confidence:
        </span>
        <span>{(info?.confidence * 100)?.toFixed(2)}%</span>
      </li>
      <li className={styles.infoItem}>
        <span className={styles.infoTitle}>
          <MdAccessTime color="#4285f4" size={18} /> Date:
        </span>
        <span>{getRelativeTime(info?.signal_timestamp)}</span>
      </li>
    </ul>
  );
}

function ChartBox({ data }) {
  return (
    <>
      {!data || data?.downsampled_adv_equity?.length === 0 ? (
        <>
          <Empty />
        </>
      ) : (
        <EquityChart data={data} />
      )}
    </>
  );
}

function HistoryBox({ history }) {
  return (
    <>
      {!history ? (
        <>
          <Empty />
        </>
      ) : (
        <TradesTable data={history} />
      )}
    </>
  );
}

function LiveSuggestionModal({ open, onClose, coin }) {
  const {
    liveSuggestion: { suggestions, status: liveStatus },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const coinSuggestion = suggestions?.find((s) => s?.symbol === coin);
  const hasBacktest = coinSuggestion?.backtest_result;

  const [selectedExchange, setSelectedExchange] = useState(
    coinSuggestion?.exchange_id || "kucoin",
  );
  const [activeTab, setActiveTab] = useState("info");

  const chartData = coinSuggestion?.backtest_result?.equity;
  const historyData = coinSuggestion?.backtest_result?.trades;

  const handleExchangeChange = (exchange) => {
    setSelectedExchange(exchange);
    return dispatch(
      getLiveSuggestion(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        exchange,
        coin,
        true,
      ),
    );
  };

  const closeModal = () => {
    setSelectedExchange("kucoin");
    setActiveTab("info");
    onClose();
  };

  const refreshSignal = () => {
    return dispatch(
      getLiveSuggestion(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        selectedExchange,
        coin,
        true,
      ),
    );
  };

  const getHistoryAndChartData = () => {
    return dispatch(
      getLiveSuggestion(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        selectedExchange,
        coin,
        true,
      ),
    );
  };

  const changeTab = (e) => {
    const targetTab = e.target.closest("li");
    if (targetTab) {
      setActiveTab(targetTab.id);
      if (!hasBacktest && liveStatus !== "loading") {
        return dispatch(
          getLiveSuggestion(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            selectedExchange,
            coin,
            true,
          ),
        );
      }
    }
  };

  return (
    <Modal
      open={open}
      onCancel={closeModal}
      footer={null}
      centered
      maskClosable={false}
      width={600}
      title={
        <div className={styles.title}>
          <AssetDisplay
            asset={coin?.split("/USDT")?.[0]}
            className={styles.assetDisplay}
          />
          <span className={styles.refreshBtn} onClick={refreshSignal}>
            <HiOutlineRefresh />
          </span>
        </div>
      }
    >
      <div className={styles.box}>
        <div className={styles.selectBox}>
          <h4>Select Exchange:</h4>
          <SelectExchange
            value={selectedExchange}
            onChange={handleExchangeChange}
            loading={liveStatus === "loading"}
            style={{ width: "120px" }}
          />
        </div>

        <p className={styles.text}>
          <strong>NOTE:</strong>
          'KuCoin' is used as the default exchange.
        </p>
      </div>

      <div className={styles.result}>
        <ul className={styles.tabs} onClick={changeTab}>
          <li
            className={`${activeTab === "info" ? styles.activeTab : ""}`}
            id="info"
          >
            Info
          </li>
          <li
            className={`${activeTab === "chart" ? styles.activeTab : ""}`}
            id="chart"
          >
            Chart
          </li>
          <li
            className={`${activeTab === "history" ? styles.activeTab : ""}`}
            id="history"
          >
            History
          </li>
        </ul>
        <div className={styles.content}>
          {liveStatus === "loading" &&
          !coinSuggestion &&
          activeTab !== "info" ? (
            <div className={styles.loader}>
              <Spin />
            </div>
          ) : liveStatus === "error" && !coinSuggestion ? (
            <ErrorBox />
          ) : (
            <>
              {activeTab === "info" && <InfoBox info={coinSuggestion} />}

              {activeTab !== "info" && (
                <span
                  className={styles.refreshBtn}
                  style={{ marginBottom: "1rem" }}
                  onClick={refreshSignal}
                >
                  <HiOutlineRefresh />
                </span>
              )}

              {activeTab === "chart" && (
                <ChartBox data={chartData} getData={getHistoryAndChartData} />
              )}
              {activeTab === "history" && (
                <HistoryBox
                  history={historyData}
                  getData={getHistoryAndChartData}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default LiveSuggestionModal;
