import { Table } from "antd";
import customStyles from "../../styles/shared/live-suggestion/TradesTable.module.css";

function TradesTable() {
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Profit/Loss",
      dataIndex: "profitLoss",
      key: "profitLoss",
    },
    {
      title: "Trader",
      dataIndex: "trader",
      key: "trader",
    },
  ];

  return (
    <Table
      className={customStyles.container}
      dataSource={[]}
      columns={columns}
      height={200}
    />
  );
}

export default TradesTable;
