import { Select } from "antd";

const { Option, OptGroup } = Select;

function StrategyLabels({ onChange, defaultValue }) {
  return (
    <Select
      placeholder="Select a strategy label"
      defaultValue={defaultValue || ""}
      onChange={onChange}
    >
      <OptGroup label="Strategy Type">
        <Option value="Momentum">Momentum</Option>
        <Option value="Mean Reversion">Mean Reversion</Option>
        <Option value="Spot">Spot</Option>
        <Option value="Future">Future</Option>
        <Option value="Trend">Trend</Option>
        <Option value="Volume">Volume</Option>
        <Option value="Volatility">Volatility</Option>
      </OptGroup>
      <OptGroup label="Data Source">
        <Option value="Social Sentiment">Social Sentiment</Option>
        <Option value="Technical Indicators">Technical Indicators</Option>
        <Option value="Fundamental Analysis">Fundamental Analysis</Option>
        <Option value="Blockchain Data">Blockchain Data</Option>
      </OptGroup>
      <OptGroup label="Risk Appetite">
        <Option value="High Risk">High Risk</Option>
        <Option value="Moderate Risk">Moderate Risk</Option>
        <Option value="Low Risk">Low Risk</Option>
        <Option value="Risk-Neutral">Risk-Neutral</Option>
      </OptGroup>
      <OptGroup label="Time Horizon">
        <Option value="Short Term">Short Term</Option>
        <Option value="Medium Term">Medium Term</Option>
        <Option value="Long Term">Long Term</Option>
      </OptGroup>
      <OptGroup label="Asset Class">
        <Option value="Cryptocurrency">Cryptocurrency</Option>
        <Option value="Stocks">Stocks</Option>
        <Option value="Forex">Forex</Option>
        <Option value="Commodities">Commodities</Option>
      </OptGroup>
      <OptGroup label="Blockchain">
        <Option value="Network activity">Network activity</Option>
        <Option value="Wallet Data">Wallet Data</Option>
      </OptGroup>
    </Select>
  );
}

export default StrategyLabels;
