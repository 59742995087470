import styles from "../../styles/Components/trade-assistant/ShowActiveVirtualPortfolio.module.css";
import React, { useEffect } from "react";
import {
  getIntegratedExchanges,
  integrateWithExchange,
} from "../../redux/exchange/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getTestExchangeBalanceChart,
  getTestExchangeComprehensiveTradeHistory,
  getTestExchangePortfolio,
  getTestExchangeTradeHistory,
} from "../../redux/test-exchange/testExchangeSlice";

function TestExchanges({ style = {}, className = "" }) {
  const {
    exchangeState: {
      integratedData,
      integratedDataLoading,
      getIntegratedList,
      integratedDataError,
    },
    testExchange: {
      portfolio,
      portfolioStatus,
      portfolioErrorMessage,
      tradeHistory,
      tradeHistoryStatus,
      tradeHistoryErrorMessage,
      comprehensiveTradeHistory,
      comprehensiveTradeHistoryStatus,
      comprehensiveTradeHistoryErrorMessage,
      portfolioFetched,
      tradeHistoryFetched,
      comprehensiveFetched,
      balanceChartStatus,
      balanceChartData,
      balanceChartError,
      balanceChartFetched,
    },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const testExchangeId = "cryptocrispy";

  const isTestExchangeIntegrated = integratedData?.find(
    (ex) => ex?.id === testExchangeId,
  );

  useEffect(() => {
    if (
      userData?.email &&
      !integratedDataLoading &&
      !getIntegratedList &&
      !integratedDataError
    ) {
      dispatch(
        getIntegratedExchanges(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [
    dispatch,
    integratedDataLoading,
    userData?.email,
    getIntegratedList,
    integratedDataError,
  ]);

  useEffect(() => {
    if (integratedDataLoading) return;

    if (getIntegratedList && !isTestExchangeIntegrated && userData?.email) {
      dispatch(
        integrateWithExchange(
          userData["custom:custom_username"],
          userData?.email,
          testExchangeId,
          testExchangeId,
          testExchangeId,
          "",
          "",
          testExchangeId,
          false,
        ),
      );
    }
  }, [
    dispatch,
    getIntegratedList,
    integratedData,
    userData,
    integratedDataLoading,
    isTestExchangeIntegrated,
  ]);

  useEffect(() => {
    if (
      portfolioStatus === "error" ||
      portfolioStatus === "loading" ||
      portfolioFetched
    )
      return;
    if (isTestExchangeIntegrated && userData?.email) {
      dispatch(
        getTestExchangePortfolio(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          testExchangeId,
        ),
      );
    }
  }, [userData?.email, isTestExchangeIntegrated, testExchangeId]);

  useEffect(() => {
    if (
      tradeHistoryStatus === "loading" ||
      tradeHistoryStatus === "error" ||
      tradeHistoryFetched
    )
      return;

    if (isTestExchangeIntegrated && userData?.email) {
      dispatch(
        getTestExchangeTradeHistory(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          testExchangeId,
        ),
      );
    }
  }, [
    dispatch,
    isTestExchangeIntegrated,
    tradeHistoryFetched,
    tradeHistoryStatus,
    userData?.email,
  ]);

  useEffect(() => {
    if (
      comprehensiveTradeHistoryStatus === "error" ||
      comprehensiveTradeHistoryStatus === "loading" ||
      comprehensiveFetched
    )
      return;

    if (isTestExchangeIntegrated && userData?.email) {
      dispatch(
        getTestExchangeComprehensiveTradeHistory(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          testExchangeId,
        ),
      );
    }
  }, [
    dispatch,
    testExchangeId,
    comprehensiveFetched,
    comprehensiveTradeHistoryStatus,
    userData?.email,
    isTestExchangeIntegrated,
  ]);

  useEffect(() => {
    if (
      balanceChartStatus === "error" ||
      balanceChartStatus === "loading" ||
      balanceChartFetched
    )
      return;
    if (isTestExchangeIntegrated && userData?.email) {
      dispatch(
        getTestExchangeBalanceChart(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          testExchangeId,
        ),
      );
    }
  }, [
    userData?.email,
    isTestExchangeIntegrated,
    testExchangeId,
    balanceChartStatus,
    balanceChartFetched,
  ]);

  return (
    <div className={`${styles.container} ${className}`} style={style}>
      <ul className={styles.exchanges}>
        <li className={styles.exchange}>
          <img
            className={styles.exchangeImg}
            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/cryptocrispy.svg`}
            alt="CryptoCrispy"
          />
          Test Exchange
        </li>
      </ul>
    </div>
  );
}

export default TestExchanges;
